import dynamic from "next/dynamic"

export default function Icon({ icon, ...props }) {
  let Icon = () => null

  switch (icon) {
    case "payment":
      Icon = dynamic(() => import("public/assets/icons/payment.svg"))
      break
    case "provider":
      Icon = dynamic(() => import("public/assets/icons/provider.svg"))
      break
    case "dove":
      Icon = dynamic(() => import("public/assets/icons/dove.svg"))
      break
    case "democracy-hands":
      Icon = dynamic(() => import("public/assets/icons/democracy-hands.svg"))
      break
    case "growth-head":
      Icon = dynamic(() => import("public/assets/icons/growth-head.svg"))
      break
    case "puzzle-bulb":
      Icon = dynamic(() => import("public/assets/icons/puzzle-bulb.svg"))
      break
    case "chat-bubbles":
      Icon = dynamic(() => import("public/assets/icons/chat-bubbles.svg"))
      break
    case "brain-heart":
      Icon = dynamic(() => import("public/assets/icons/brain-heart.svg"))
      break
    case "balance-scale":
      Icon = dynamic(() => import("public/assets/icons/balance-scale.svg"))
      break
    case "question-mark":
      Icon = dynamic(() => import("public/assets/icons/question-mark.svg"))
      break
    case "group-people":
      Icon = dynamic(() => import("public/assets/icons/group-people.svg"))
      break
    case "map-pin":
      Icon = dynamic(() => import("public/assets/icons/map-pin.svg"))
      break
    case "analog-clock":
      Icon = dynamic(() => import("public/assets/icons/analog-clock.svg"))
      break
    case "heart-chat":
      Icon = dynamic(() => import("public/assets/icons/heart-chat.svg"))
      break
    case "mental-health-heart":
      Icon = dynamic(() => import("public/assets/icons/mental-health-heart.svg"))
      break
    case "location":
      Icon = dynamic(() => import("public/assets/icons/location.svg"))
      break
    case "pride-heart":
      Icon = dynamic(() => import("public/assets/icons/pride-heart.svg"))
      break
    default:
      console.error("An invalid value was provided to the IconList component's icon property")
  }

  return <Icon {...props} />
}
