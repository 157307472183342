import IconCard from "components/DynamicComponent/atoms/IconCard"
import Decoration from "public/assets/news-background.svg"

export default function IconCardOverlay({ blok }) {
  return (
    <section className="relative bg-springWood px-6 py-10 md:pt-20 lg:pt-0">
      <div className="relative z-10 mx-auto flex max-w-screen-xl flex-col flex-wrap justify-between gap-20 lg:-mt-8 lg:flex-row lg:gap-8">
        <Decoration
          aria-hidden
          className="pointer-events-none absolute -right-[48rem] z-0 max-h-[600px] w-[100rem] sm:-right-12 lg:-top-56 lg:right-0 lg:scale-105"
        />
        {blok.cards.map((card) => (
          <IconCard key={card._uid} blok={card} />
        ))}
      </div>
    </section>
  )
}
