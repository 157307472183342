import Icon from "components/Icon"
import StoryblokVisualEditor from "components/StoryblokVisualEditor"
import richText from "utilities/richText"

export default function IconCard({ blok }) {
  return (
    <StoryblokVisualEditor story={blok}>
      <div className="relative mx-auto flex max-w-xl lg:flex-[1_1_280px]">
        <div className="absolute -top-[3.3rem] left-1/2 -translate-x-1/2">{blok.icon && <Icon icon={blok.icon} />}</div>
        <div className="bg-white p-6 pt-12 shadow-md">
          {blok.heading && <h4 className="text-center text-l2 font-medium text-teal-dark">{blok.heading}</h4>}
          <div>{richText(blok.description)}</div>
        </div>
      </div>
    </StoryblokVisualEditor>
  )
}
